import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-accordion-item',
  templateUrl: './accordion-item.component.html',
  styleUrls: ['./accordion-item.component.css'],
})
export class AccordionItemComponent {
  expanded: boolean = false;

  @Output()
  expandedEvent: EventEmitter<boolean> = new EventEmitter();

  toggleExpanded(): void {
    this.expanded = !this.expanded;
    this.expandedEvent.emit(this.expanded);
  }

  // expandedEventEmit(): void {

  // }
}
