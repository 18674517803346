import { Component, Input } from '@angular/core';
import { SuggestionsService } from '../services/suggestions.service';

@Component({
  selector: 'app-suggestion-card',
  templateUrl: './suggestion-card.component.html',
  styleUrls: ['./suggestion-card.component.css'],
})

export class SuggestionCardComponent {

    constructor(
        private suggestionsService: SuggestionsService
    ) {}

    @Input() title: string = 'Help';

    @Input() suggestion1: string = 'Lorem ipsum';

    @Input() suggestion2: string = 'Dolor sit amet';

    onClickSuggestion(suggestion: string) {
        this.suggestionsService.getSuggestions().next(suggestion);
    }
}
