import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Subscription } from 'rxjs';
import { Message } from '../interfaces/message';
import { ChatbotService } from '../services/chatbot.service';
import { SuggestionsService } from '../services/suggestions.service';
import { TextToSpeechService } from '../services/text-to-speech.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css'],
})
export class ChatComponent implements OnInit, OnDestroy {
  @ViewChild('chat') private chat!: ElementRef;

  messages: Message[] = [];
  suggestionsSubscription?: Subscription;
  clientNameSubscription?: Subscription;
  responseSubscription?: Subscription;
  stopMessageSubscription?: Subscription;
  messageSubscription?: Subscription;

  isWriting: boolean = false;

  scrollInterval: any;

  regex = /[^\w\s.,;:'"()&àèéìòù]/g;

  //regex to find if there are alphanumeric characters
  alphanumericRegex = /[a-z0-9]/i;

  constructor(
    private ngZone: NgZone,
    private changeDetector: ChangeDetectorRef,
    private suggestionsService: SuggestionsService,
    private chatbotService: ChatbotService,
    private ttsService: TextToSpeechService,
    private cookieService: CookieService,
    private router: Router,
    private el: ElementRef
  ) {}

  ngOnInit() {
    this.stopMessageSubscription = this.chatbotService
      .getStopMessage$()
      .subscribe((stop: boolean) => {
        if (stop) {
          this.stopMessage();
        }
      });

    this.messageSubscription = this.chatbotService
      .getMessage$()
      .subscribe((msg) => {
        this.sendMessage(msg);
      });

    this.suggestionsSubscription = this.suggestionsService
      .getSuggestions$()
      .subscribe((suggestion) => {
        this.sendMessage(suggestion);
      });

    this.clientNameSubscription = this.chatbotService
      .getClientName$()
      .subscribe((clientName) => {
        this.chatbotService
          .getClientDetails(clientName)
          .subscribe((clientDetails) => {
            this.messages.push({
              sender: 'bot',
              text: null,
              client_details: null,
              links: null,
              client_details_response: clientDetails,
            });
          });
      });
  }

  ngOnDestroy() {
    this.suggestionsSubscription?.unsubscribe();
    this.stopMessageSubscription?.unsubscribe();
    this.messageSubscription?.unsubscribe();
  }

  sendMessage(message: string) {
    // Controllo che ci sia effettivamente un messaggio
    if (message && message.trim() !== '') {
      // Disabilito l'input
      this.chatbotService.getInputDisable().next(true);

      this.scrollInterval = setInterval(() => {
        this.scrollToBottom();
      }, 500);

      this.isWriting = true;

      // Invio il messaggio
      this.messages.push({
        sender: 'user',
        text: message,
        client_details: null,
        links: null,
        client_details_response: null,
      });

      this.changeDetector.detectChanges();

      let el = {
        sender: 'bot' as 'bot',
        text: '',
        client_details: null,
        links: null,
        client_details_response: null,
      };
      this.messages.push(el);

      setTimeout(() => {
        this.scrollToBottom();
      }, 100);

      // In ordine:
      // variabile che contiente il TTS effettivo,
      // variabile che contiene la prima frase per il delay,
      // variabile che controlla se è stata fatta la prima frase
      let textToSpeech = '';
      let firstText = '';
      let firstTextDone = false;

      // Mando il messaggio al WebSocket
      this.responseSubscription = this.chatbotService
        .getWebSocket()
        .subscribe((msg: any) => {
          this.ngZone.run(() => {
            if (msg.status === 'EXPIRED') {
              this.cookieService.delete('token');
              this.router.navigate(['/login']);
            }
            if (msg.status === 'UNAUTHORIZED') {
              this.cookieService.delete('token');
              this.router.navigate(['/login']);
            }
            if (msg.text || msg.is_last) {
              // Riabilito l'input e rimetto il focus se mi arriva l'ultima parola
              if (msg.is_last || !this.isWriting) {
                if (el.text === '') el.text = ' ';
                this.responseSubscription!.unsubscribe();
                this.chatbotService.getInputDisable().next(false);
                this.scrollToBottom();
                clearInterval(this.scrollInterval);
                this.isWriting = false;
              }

              // Se ho fatto la prima frase, aggiungo le parole al testo mostrato, se no al testo della prima frase
              if (firstTextDone) {
                el.text += msg.text;
              } else {
                firstText += msg.text;
              }

              // Aggiungo il testo al testo che dovrò mandare al TTS
              textToSpeech += msg.text;

              // Manda il TTS ogni . o , e resetta la var textToSpeech
              if (
                textToSpeech.includes('.') ||
                textToSpeech.includes(',') ||
                textToSpeech.includes(':') ||
                textToSpeech.includes('!') ||
                msg.is_last
              ) {
                let sanitizedText = textToSpeech.replace(this.regex, '');
                if (this.alphanumericRegex.test(sanitizedText)) {
                  this.ttsService.addRequest(sanitizedText);
                }

                // Mando il testo al TTS in coda
                //this.ttsService.addRequest();

                // Se non ho ancora fatto la prima frase, allora metto la prima frase nel testo mostrato e cambio la flag
                if (!firstTextDone) {
                  firstTextDone = true;
                  el.text = firstText;
                }

                // Reset del testo da mandare al TTS
                textToSpeech = '';
              }
            }
            if (msg.client_details) {
              el.client_details = msg.client_details;
            }
            if (msg.links) {
              el.links = msg.links;
            }
          });
        });
      this.chatbotService.getWebSocket().next({
        message: message,
        Authorization: this.cookieService.get('token'),
      });
    }
  }

  sendStreamingMessage(message: string) {
    this.messages.push({
      sender: 'user',
      text: message,
      client_details: null,
      links: null,
      client_details_response: null,
    });
    this.changeDetector.detectChanges();
    let el = {
      sender: 'bot' as 'bot',
      text: '...',
      client_details: null,
      links: null,
      client_details_response: null,
    };

    this.messages.push(el);
  }

  scrollToBottom(): void {
    window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
  }

  stopMessage() {
    this.responseSubscription!.unsubscribe();
    this.isWriting = false;
    clearInterval(this.scrollInterval);
    if (this.messages[this.messages.length - 1].text === '') {
      this.messages[this.messages.length - 1].text = ' ';
    }
    this.chatbotService.getInputDisable().next(false);
  }
}
