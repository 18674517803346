import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
@Injectable()

export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private cookieService: CookieService,
    private router: Router
) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (!this.cookieService.check('token')) {
            this.router.navigate(['/login']);
        }
        if (this.cookieService.check('token')) {
            request = request.clone({
                setHeaders: {
                    Authorization: 'Bearer ' + this.cookieService.get('token'),
                },
            });
        }
        return next.handle(request);
    }
}
