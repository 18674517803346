<div
    class="flex flex-row items-end h-[100px] fixed bottom-[0] left-[0] w-full border-t-[1px] border-solid border-grey-200 backdrop-blur-md dark:border-dk-blue">
    <div class="flex-grow my-auto">
        <div class="relative max-w-[960px] max-lg:mx-[15px] mx-auto rounded-xl my-auto">
            <textarea autofocus autosize [maxRows]="3" [useImportant]="true"
                (keydown.enter)="$event.preventDefault(); sendMessage(message.value); message.value = '';" type="text"
                [disabled]="isRecording || isInputDisabled" [placeholder]="isRecording ? '' : 'Come posso aiutarti ?'"
                [(ngModel)]="textFromSpeech"
                class="dark:text-white-400 dark:placeholder:text-white-400 my-auto resize-none hover:shadow-none focus:shadow-none transition-shadow shadow-md pl-4 max-xl:pr-4 pr-[256px] flex w-full border rounded-xl focus:outline-none focus:border-a-light dark:focus:border-white-200 bg-white dark:border-dk-blue-300 dark:bg-dk-blue-200 h-[44px] py-[10px]"
                #message></textarea>
            <div
                class="absolute flex items-center justify-center hover:scale-125 h-full mr-4 right-0 top-0 text-gray-400 dark:text-grey-200">
                <div *ngIf="isInputDisabled" class="cursor-pointer" (click)="stopMessage()">
                    <svg class="fill-[#9ca3af] dark:fill-white-200" width="24" height="24" fill="none"
                        viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10ZM9.5 8h5A1.5 1.5 0 0 1 16 9.5v5a1.5 1.5 0 0 1-1.5 1.5h-5A1.5 1.5 0 0 1 8 14.5v-5A1.5 1.5 0 0 1 9.5 8Z" />
                    </svg>
                </div>
                <svg (click)="startClickRecording($event)" *ngIf="!isInputDisabled && !isRecording && !messageSent"
                    class="hover:scale-100 w-6 h-6 stroke-gray-400 dark:stroke-white-200 z-10 cursor-pointer"
                    width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                    stroke-width="1.5">
                    <rect class="fill-gray-400 dark:fill-white-200" x="9" y="2" width="6" height="12" rx="3"
                        stroke-width="1.5" stroke-width="1.5"></rect>
                    <path d="M5 10V11C5 14.866 8.13401 18 12 18V18V18C15.866 18 19 14.866 19 11V10" stroke-width="1.5"
                        stroke-linecap="round" stroke-linejoin="round"></path>
                    <path d="M12 18V22M12 22H9M12 22H15" stroke-width="1.5" stroke-linecap="round"
                        stroke-linejoin="round"></path>
                </svg>
                <svg *ngIf="messageSent" width="24px" height="24px" viewBox="0 0 24 24" fill="none"
                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" class="w-6 h-6 cursor-pointer">
                    <path stroke-linecap="round" stroke-linejoin="round"
                        d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5" />
                </svg>
                <div *ngIf="isRecording && messageSent" class="circle absolute mr-[0px]"></div>
            </div>
        </div>
    </div>
</div>