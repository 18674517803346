import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuard } from './core/guards/auth.guard';

const routes: Routes = [
    {
        path: '',
        canActivate: [authGuard],
        loadChildren: () => import('./features/chatbot/chatbot.module').then((m) => m.ChatbotModule)
    },
    {
        path: 'login',
        loadChildren: () => import('./features/login/login.module').then((m) => m.LoginModule)
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
    exports: [RouterModule]
})

export class AppRoutingModule { }
