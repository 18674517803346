import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { enviroment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})

export class AzureSpeechToTextService {

    private apiUrl = enviroment.endpointHttp;

    constructor(
        private http: HttpClient) {
    }

    transcribeAudio(file: any): Observable<any> {
        const formData: FormData = new FormData();
        formData.append('file', file, file.name);
        return this.http.post<any>(`${this.apiUrl}transcribe/`, formData);
    }
}
