import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SuggestionsService {

  suggestions = new Subject<string>();

  constructor() { }

  getSuggestions() {
    return this.suggestions;
  }

  getSuggestions$() {
    return this.suggestions.asObservable();
  }

}
