<div class="py-2 border-solid border-grey-50 border-b-[1px]">
    <h2>
        <button class="flex items-center justify-between w-full text-left font-semibold" (click)="toggleExpanded()">
            <ng-content select="[title]"></ng-content>
            <svg class="fill-grey-900 shrink-0 ml-8" width="16" height="16" xmlns="http://www.w3.org/2000/svg">
                <rect y="7" width="16" height="2" rx="1"
                    class="transform origin-center transition duration-200 ease-out"
                    [ngClass]="{'rotate-180': expanded}" />
                <rect y="7" width="16" height="2" rx="1"
                    class="transform origin-center rotate-90-custom transition duration-200 ease-out"
                    [ngClass]="{'rotate-180': expanded}" />
            </svg>
        </button>
    </h2>
    <div class="grid text-sm text-slate-600 overflow-hidden transition-all duration-300 ease-in-out"
        [ngClass]="{'grid-rows-[1fr] opacity-100': expanded, 'grid-rows-[0fr] opacity-0': '!expanded'}">
        <div class="overflow-hidden">
            <p class="pb-3">
                <ng-content select="[body]"></ng-content>
            </p>
        </div>
    </div>
</div>