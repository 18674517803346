import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { TextToSpeechService } from '../services/text-to-speech.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  public isMuted: boolean = false;

  @Input() isDarkMode: boolean = false;

  @Output() darkModeEmitter: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private ttsService: TextToSpeechService,
    private router: Router
  ) {}

  toggleMute(): void {
    this.isMuted = !this.isMuted;
    this.ttsService.toggleMute();
    if (this.isMuted) {
      localStorage.setItem('isMuted', 'true');
    } else {
      localStorage.setItem('isMuted', 'false');
    }
  }

  ngOnInit(): void {
    if (localStorage.getItem('isMuted') === 'false') {
      this.isMuted = false;
    } else {
      this.isMuted = true;
      this.ttsService.toggleMute();
    }
    // this.darkModeEmitter.emit(this.isDarkMode);
  }

  goHome() {
    this.router
      .navigateByUrl('/login', { skipLocationChange: true })
      .then(() => this.router.navigate(['/']));
  }

  isAudioVisible(): boolean {
    return this.router.url != '/login';
  }

  setTheme = (isDarkMode: boolean) => {
    this.isDarkMode = !isDarkMode;
    this.darkModeEmitter.emit(this.isDarkMode);
  };
}
