import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AutosizeModule } from 'ngx-autosize';
import { MarkdownModule } from 'ngx-markdown';
import { AccordionItemComponent } from './accordion-item/accordion-item.component';
import { ChatComponent } from './chat/chat.component';
import { ChatbotRoutingModule } from './chatbot.routing';
import { ChatbotComponent } from './chatbot/chatbot.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { LoadingMessageComponent } from './loading-message/loading-message.component';
import { LoadingComponent } from './loading/loading.component';
import { MessageComponent } from './message/message.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { SuggestionCardComponent } from './suggestion-card/suggestion-card.component';
import { SuggestionsComponent } from './suggestions/suggestions.component';

@NgModule({
  declarations: [
    SidebarComponent,
    ChatComponent,
    MessageComponent,
    LoadingComponent,
    SuggestionsComponent,
    SuggestionCardComponent,
    FooterComponent,
    HeaderComponent,
    LoadingMessageComponent,
    AccordionItemComponent,
    ChatbotComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    AutosizeModule,
    HttpClientModule,
    ChatbotRoutingModule,
    MarkdownModule.forChild(),
  ],
  exports: [FooterComponent, HeaderComponent, ChatComponent],
})
export class ChatbotModule {}
