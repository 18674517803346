import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  isDark?: boolean;
  ngOnInit(): void {
    if (localStorage.getItem('theme') === 'light') {
      this.isDark = false;
      document.documentElement.classList.remove('dark')}
    else {
      this.isDark = true;
      document.documentElement.classList.add('dark')
    }
  }
  title = 'ai-chatbot';

  onClick() {
    if (this.isDark) {
      localStorage.setItem('theme', 'dark')
      document.documentElement.classList.add('dark')}
    else{
      localStorage.setItem('theme', 'light')
      document.documentElement.classList.remove('dark')}
  }

  openSideBar() {
    document.querySelector('.sidebar')!.classList.toggle('left-[-300px]')
  }
}

