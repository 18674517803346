<div class="relative py-3 px-6 dark:bg-dk-blue-200 col-start-1 col-end-13 bg-grey-25 border-solid border-l-[1px] border-r-[1px] border-t-[1px] border-grey-200 dark:border-dk-blue-300"
    [ngClass]="{'rounded-b-lg border-b-[1px] ': isLast, 'rounded-t-lg': isFirst}">
    <div class="flex items-start">
        <div *ngIf="hasAvatar"
            class="flex items-center justify-center h-10 w-10 rounded-full mr-[12px] text-white flex-shrink-0">
            <ng-container *ngIf="isDarkMode; else isNotDarkMode">
                <img *ngIf="sender === 'user'" class="rounded-full" src="../../assets/user_light.svg" alt="">
                <img *ngIf="sender === 'bot'" class="rounded-full" src="../../assets/bot_light.svg" alt="">
            </ng-container>
            <ng-template #isNotDarkMode>
                <img *ngIf="sender === 'user'" class="rounded-full" src="../../assets/user.svg" alt="">
                <img *ngIf="sender === 'bot'" class="rounded-full" src="../../assets/bot.svg" alt="">
            </ng-template>
        </div>
        <div class="overflow-x-auto relative text-sm pt-3 pb-2 w-full"
            [ngClass]="{'text-grey-600 dark:text-white-100': sender === 'user'}">
            <div *ngIf="message.text === ''" class="h-[20px]">
                <app-loading-message></app-loading-message>
            </div>
            <div *ngIf="message.text" class="dark:text-white-100">
                <markdown [data]="message.text"></markdown>
            </div>
            <div *ngIf="message.links">
                <div *ngFor="let link of message.links" class="mt-2">
                    <button (click)="onClickDownload(link.path)" type="button"
                        class="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-full text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:text-white-100 dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">
                        <span class="mr-1">Download</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="message.client_details" class="mt-2">
        <app-accordion-item (expandedEvent)="onClickAccordion(client_detail.id, $event)"
            *ngFor="let client_detail of message.client_details">
            <div class="font-semibold text-[24px] text-grey-900 py-4 w-full" title>{{client_detail.name}}</div>
            <div class="bg-grey-200 p-[25px] rounded-lg" body>
                <app-loading-message *ngIf="message.client_details_response == null"></app-loading-message>
                <div *ngIf="message.client_details != null">
                    <div class="font-medium text-[16px] text-grey-900 py-2">{{
                        message.client_details_response?.client_profession }}</div>
                    <div class="font-normal text-[16px] text-grey-600 pt-2 pb-4">{{
                        message.client_details_response?.client_email }}</div>
                    <div class="font-medium text-[16px] p-[16px] bg-grey-25 rounded-lg">
                        <div class="font-semibold text-[20px] text-grey-800 pb-5"><span>Informazioni</span></div>
                        <div class="border-b-[1px] border-solid border-grey-200 py-4">
                            <span class="text-grey-500 ">Asset Totale: </span>
                            <span class="text-grey-600">{{ message.client_details_response?.client_total_asset
                                }}</span><br>
                        </div>
                        <div class="border-b-[1px] border-solid border-grey-200 py-4">
                            <span class="text-grey-500">Asset Familiare Totale: </span>
                            <span class="text-grey-600">{{ message.client_details_response?.client_total_family_asset
                                }}</span><br>
                        </div>
                        <div class="border-b-[1px] border-solid border-grey-200 py-4">
                            <span class="text-grey-500">Collezione Netta YTD: </span>
                            <span class="text-grey-600">{{ message.client_details_response?.client_net_collection_ytd
                                }}</span><br>
                        </div>
                        <div class="border-b-[1px] border-solid border-grey-200 py-4">
                            <span class="text-grey-500">AUM Gestita: </span>
                            <span class="text-grey-600">{{ message.client_details_response?.client_aum_gestita
                                }}</span><br>
                        </div>
                        <div class="border-b-[1px] border-solid border-grey-200 py-4">
                            <span class="text-grey-500">AUM Cash: </span>
                            <span class="text-grey-600">{{ message.client_details_response?.client_aum_cash
                                }}</span><br>
                        </div>
                        <div class="border-b-[1px] border-solid border-grey-200 py-4">
                            <span class="text-grey-500">AUM Stock: </span>
                            <span class="text-grey-600">{{ message.client_details_response?.client_aum_stock
                                }}</span><br>
                        </div>
                        <div class="border-b-[1px] border-solid border-grey-200 py-4">
                            <span class="text-grey-500">AUM Bonds: </span>
                            <span class="text-grey-600">{{ message.client_details_response?.client_aum_bonds
                                }}</span><br>
                        </div>
                        <div class="border-b-[1px] border-solid border-grey-200 py-4">
                            <span class="text-grey-500">AUM Altro: </span>
                            <span class="text-grey-600">{{ message.client_details_response?.client_aum_other
                                }}</span><br>
                        </div>
                        <div class="border-b-[1px] border-solid border-grey-200 py-4">
                            <span class="text-grey-500">Proprietà Immobiliari: </span>
                            <span class="text-grey-600">{{
                                message.client_details_response?.client_real_estate_properties }}</span><br>
                        </div>
                        <div class="border-b-[1px] border-solid border-grey-200 py-4">
                            <span class="text-grey-500">Interessi Finanziari: </span>
                            <span class="text-grey-600">{{ message.client_details_response?.client_financial_intrests
                                }}</span><br>
                        </div>
                        <div class="border-b-[1px] border-solid border-grey-200 py-4">
                            <span class="text-grey-500">Propensione al Rischio: </span>
                            <span class="text-grey-600">{{ message.client_details_response?.client_risk_appetite
                                }}</span><br>
                        </div>
                        <div class="py-4">
                            <span class="text-grey-500">Investimento Timeframe: </span>
                            <span class="text-grey-600">{{ message.client_details_response?.client_investment_timeframe
                                }}</span><br>
                        </div>
                    </div>
                </div>
            </div>
        </app-accordion-item>
    </div>
</div>