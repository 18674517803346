import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject, concatMap } from 'rxjs';
import { enviroment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TextToSpeechService {
  private audio: HTMLAudioElement = new Audio();

  private audioQueue: string[] = [];

  private isPlaying: boolean = false;

  private isMuted: boolean = false;

  private requestQueue = new Subject<string>();

  constructor(private http: HttpClient) {
    this.audio.addEventListener('ended', () => this.playNext());
    this.requestQueue
      .pipe(concatMap((text) => this.getSpeech(text)))
      .subscribe((audioBlob) => {
        this.playAudio(audioBlob);
      });
  }

  getSpeech(text: string) {
    const requestOptions = {
      responseType: 'blob' as 'json',
    };
    return this.http.post<Blob>(
      enviroment.endpointHttp + 'audio',
      { text: text },
      requestOptions
    );
  }

  playAudio(data: Blob) {
    // Aggiungi l'URL dell'audio alla coda
    this.audioQueue.push(URL.createObjectURL(data));

    // Se non è in corso alcuna riproduzione, avvia il processo di riproduzione
    if (!this.isPlaying) {
      this.playNext();
    }
  }

  private playNext() {
    // Se la coda è vuota, interrompi la riproduzione
    if (this.audioQueue.length === 0) {
      this.isPlaying = false;
      return;
    }

    // Prendi il primo elemento dalla coda
    const nextAudioUrl = this.audioQueue.shift();

    // Riproduci l'audio
    if (nextAudioUrl) {
      this.audio.src = nextAudioUrl;
      this.audio.load();
      this.audio.play();
      this.isPlaying = true;
    }
  }

  addRequest(text: string): void {
    if (this.isMuted) {
      this.audioQueue = [];
    } else {
      if (text.trim() !== '') {
        this.requestQueue.next(text);
      }
    }
  }

  toggleMute(): void {
    this.isMuted = !this.isMuted;
    if (this.isMuted) {
      this.audioQueue = [];
      this.audio.pause();
      this.isPlaying = false;
    }
    this.audio.muted = !this.audio.muted;
  }
}
