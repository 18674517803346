import { Component, DoCheck, Input, OnDestroy, OnInit } from '@angular/core';
import { Message } from '../interfaces/message';
import { ChatbotService } from '../services/chatbot.service';
import { SuggestionsService } from '../services/suggestions.service';
import { enviroment } from 'src/environments/environment';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.css'],
})
export class MessageComponent implements OnInit, DoCheck, OnDestroy {
  @Input() sender: 'user' | 'bot' = 'user';

  @Input() message!: Message;

  @Input() isLast: boolean = false;

  @Input() isFirst: boolean = false;

  public hasDivider: boolean = true;

  public hasAvatar: boolean = true;

  public file: any;

  public enviroment = enviroment;

  public isDarkMode: boolean = localStorage.getItem('darkMode') === 'true';

  constructor(
    private suggestionsService: SuggestionsService,
    private chatbotService: ChatbotService
  ) {}

  onClickClientName(id: number) {
    this.chatbotService.getClientName().next(id);
  }

  onClickAccordion(id: number, expanded: boolean) {
    if (this.message.client_details_response === null && expanded) {
      this.chatbotService
        .getClientDetails(id)
        .subscribe((clientDetailsResponse) => {
          this.message.client_details_response = clientDetailsResponse;
        });
    }
  }

  ngOnInit() {
    setTimeout(() => {
      this.chatbotService.scrollToBottom();
    }, 100);
    if (this.message.client_details_response) {
      this.hasAvatar = false;
      this.hasDivider = false;
    }
  }

  ngDoCheck(): void {
    const darkMode = window.localStorage.getItem('darkMode');
    this.isDarkMode = darkMode == 'true' ? true : false;
  }

  ngOnDestroy() {
    this.chatbotService.getClientName().unsubscribe();
  }

  onClickDownload(path: string) {
    this.chatbotService.postDownload(path).subscribe((response) => {
      this.file = response;
      var blob = new Blob([this.file], { type: 'application/pdf' });
      var url = window.URL.createObjectURL(blob);
      window.open(url);
    });
  }
}
