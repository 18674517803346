import {
    Component,
    ElementRef,
    HostListener,
    OnInit,
    ViewChild,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { ChatbotService } from '../services/chatbot.service';
import { AzureSpeechToTextService } from '../services/azure-speech-to-text.service';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.css'],
})
export class FooterComponent implements OnInit {
    @ViewChild('message') inputChat!: ElementRef;

    private mediaRecorder: MediaRecorder | null = null;

    private recordedChunks: BlobPart[] = [];

    private audioBlob: Blob | null = null;

    public isRecording: boolean = false;

    public textFromSpeech: string = '';

    public messageSent: boolean = false;

    private response: string = '';

    public isInputDisabled: boolean = false;

    private recMouseDown: boolean = false;

    private recKeyDown: boolean = false;

    private audioFilePath = '/assets/audio_recording.mp3';

    private textFromSpeechSubscription!: Subscription;

    private chatBotSubscription!: Subscription;

    private voiceRecognitionSubscription!: Subscription;

    private responseSubscription!: Subscription;

    private inputDisableSubscription!: Subscription;

    // @HostListener('window:blur', ['$event']) onBlur(event: any): void {
    //   if (this.isRecording) {
    //     this.isRecording = false;
    //     this.stopRecording();
    //     this.recKeyDown = false;
    //   }
    // }

    @HostListener('window:mouseup', ['$event']) stopClickRecording() {
        this.recMouseDown = false;
        if (this.isRecording && !this.recKeyDown) {
            this.stopRecording();
        }
    }

    constructor(
        private chatbotService: ChatbotService,
        private azureSpeechService: AzureSpeechToTextService
    ) { }

    ngOnInit(): void {
        this.inputDisableSubscription = this.chatbotService
            .getInputDisable$()
            .subscribe((isDisabled) => {
                this.isInputDisabled = isDisabled;
                if (!isDisabled) {
                    setTimeout(() => {
                        this.inputChat.nativeElement.focus();
                    }, 50);
                }
            });
    }

    ngOnDestroy(): void {
        this.chatBotSubscription?.unsubscribe();
        this.voiceRecognitionSubscription?.unsubscribe();
        this.inputDisableSubscription?.unsubscribe();
    }

    sendMessage(message: string): void {
        this.chatbotService.getMessage().next(message);
    }

    stopMessage(): void {
        this.chatbotService.getStopMessage().next(true);
    }

    startClickRecording(event: any) {
        if (!this.isRecording && !this.isInputDisabled && !this.recKeyDown) {
            this.startRecording();
            this.recMouseDown = true;
            this.messageSent = true;
        }
    }

    startRecording() {
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            this.isRecording = true;
            navigator.mediaDevices
                .getUserMedia({ audio: true })
                .then((stream) => {
                    const options = { mimeType: 'audio/webm;codecs=opus' };
                    this.mediaRecorder = new MediaRecorder(stream, options);
                    this.recordedChunks = [];
                    this.mediaRecorder.ondataavailable = (event) => {
                        if (event.data.size > 0) {
                            this.recordedChunks.push(event.data);
                        }
                    };
                    this.mediaRecorder.onstop = () => {
                        this.audioBlob = new Blob(this.recordedChunks, {
                            type: 'audio/webm; codecs=opus',
                        });
                        this.sendRecordingToServer(this.audioBlob);
                        stream.getTracks().forEach((track) => track.stop());
                    };
                    this.mediaRecorder.start();
                })
                .catch((error) =>
                    console.error('Error accessing media devices.', error)
                );
        }
    }

    stopRecording() {
        this.mediaRecorder?.stop(); // This will trigger the onstop event
        this.isRecording = false;
        this.mediaRecorder = null; // Reset mediaRecorder to null
        this.messageSent = false;
    }

    sendRecordingToServer(blob: any) {
        let file = new File([blob], 'audio.webm', {
            type: 'audio/webm; codecs=opus'
        });
        this.azureSpeechService.transcribeAudio(file).subscribe({
            next: (response) => {
                this.sendMessage(response.text);
                this.messageSent = false;
            },
            error: (error) => {
                console.error('Transcription error:', error);
            },
        });
    }
}
