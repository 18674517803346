<div
    class="bg-white dark:bg-dk-blue-200 dark:border dark:border-dk-blue-300 min-w-[100px] rounded-xl p-4 sm:mb-4 text-gray-400 shadow-md max-sm:mb-[10px]">
    <div class="text-grey-800 dark:text-white-200 lg:mb-[60px] max-sm:mb-0 font-semibold text-[30px] max-sm:w-[160px]">
        {{ title }}
    </div>
    <div (click)="onClickSuggestion(suggestion1)"
        class="text-grey-700 dark:text-white-400 flex justify-between py-3 border-solid border-grey-300 dark:border-dk-blue-300 border-b-[1px] cursor-pointer stroke-grey-400 hover:stroke-grey-800 hover:text-grey-800 dark:stroke-white-200 dark:hover:stroke-white-200">
        <div class="overflow-ellipsis whitespace-nowrap overflow-hidden  font-medium text-[16px]">{{ suggestion1 }}
        </div>
        <svg class="my-auto" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 11L11 1M11 1H1M11 1V11" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    </div>
    <div (click)="onClickSuggestion(suggestion2)"
        class="text-grey-700 dark:text-white-400 flex justify-between py-3 cursor-pointer stroke-grey-400 hover:stroke-grey-800 hover:text-grey-800 dark:stroke-white-200 dark:hover:stroke-white-200">
        <div class="overflow-ellipsis whitespace-nowrap overflow-hidden font-medium text-[16px]">{{ suggestion2 }}</div>
        <svg class="my-auto" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 11L11 1M11 1H1M11 1V11" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    </div>
</div>