import { Component, HostBinding, OnInit, effect, signal } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  public expanded: boolean = false;

  public animation: boolean = false;

  public isDarkMode: boolean = false;

  public darkMode = signal<boolean>(
    JSON.parse(window.localStorage.getItem('darkMode') ?? 'false')
  );

  @HostBinding('class.dark') get mode() {
    return this.darkMode();
  }

  constructor(public router: Router) {
    effect(() => {
      if (this.darkMode()) {
        document.body.style.setProperty('--circle-color', '#A5F3FC');
        document.body.style.setProperty('--circle2-color', '#3B82F6');
        document.body.classList.remove('body-light');
        document.body.classList.add('body-dark');
        document.querySelector('#body-chat')?.classList.add('body-dark');
        document.querySelector('#body-chat')?.classList.remove('body-light');
      } else {
        document.body.style.setProperty('--circle-color', '#2e0e73');
        document.body.style.setProperty('--circle2-color', '#f2766b');
        document.body.classList.remove('body-dark');
        document.body.classList.add('body-light');
        document.querySelector('#body-chat')?.classList.remove('body-dark');
        document.querySelector('#body-chat')?.classList.add('body-light');
      }
      window.localStorage.setItem('darkMode', JSON.stringify(this.darkMode()));
    });
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.animation = true;
    }, 500);
  }

  toggleExpanded(): void {
    this.expanded = !this.expanded;
  }

  setTheme = (isDarkMode: boolean) => {
    if (isDarkMode) {
      document.body.style.setProperty('--circle-color', '#A5F3FC');
      document.body.style.setProperty('--circle2-color', '#3B82F6');
      document.body.classList.remove('body-light');
      document.body.classList.add('body-dark');
      document.querySelector('#body-chat')?.classList.add('body-dark');
      document.querySelector('#body-chat')?.classList.remove('body-light');
    } else {
      document.body.style.setProperty('--circle-color', '#2e0e73');
      document.body.style.setProperty('--circle2-color', '#f2766b');
      document.body.classList.remove('body-dark');
      document.body.classList.add('body-light');
      document.querySelector('#body-chat')?.classList.remove('body-dark');
      document.querySelector('#body-chat')?.classList.add('body-light');
    }
    this.darkMode.set(isDarkMode);
    this.isDarkMode = isDarkMode;
  };
}
