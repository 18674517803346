import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.css']
})
export class LoadingComponent implements OnInit {
  isLoading: boolean = true;
  isHidden: boolean = false;

  ngOnInit() {
    setTimeout(()=>{
        this.isHidden = true
    }, 6500);


  }

  onClick() {
    this.isLoading = false;
    setTimeout(()=>{ this.isHidden = true }, 500);
  }
}
