
<span class="absolute text-4xl top-5 left-4 cursor-pointer " (click)="openSideBar()">
    <svg  class="px-2 fill-t-light dark:fill-t-dark  bg-a-light  rounded-md" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="50" viewBox="0 0 50 50">
      <path d="M 5 8 A 2.0002 2.0002 0 1 0 5 12 L 45 12 A 2.0002 2.0002 0 1 0 45 8 L 5 8 z M 5 23 A 2.0002 2.0002 0 1 0 5 27 L 45 27 A 2.0002 2.0002 0 1 0 45 23 L 5 23 z M 5 38 A 2.0002 2.0002 0 1 0 5 42 L 45 42 A 2.0002 2.0002 0 1 0 45 38 L 5 38 z"></path>
      </svg>
</span>
<div  class="sidebar fixed top-0 bottom-0 lg:left-0 left-[-300px]
  p-2 w-[300px] overflow-y-auto text-center bg-s-light dark:bg-s-dark shadow h-screen">
  <div class=" text-xl">
    <div class="p-2.5 mt-1 flex items-center rounded-md ">
      <div *ngIf="isDark; else isLight" class="">
        <img
          src="../assets/logo-dark.png"
          alt="Avatar"
          class="h-full w-full"
        />
      </div>
      <ng-template #isLight>
        <div  class="">
          <img
            src="../assets/logo-light.png"
            alt="Avatar"
            class="h-full w-full"
          />
        </div>
      </ng-template>
      <i class="ml-20 cursor-pointer lg:hidden" (click)="openSideBar()">X</i>
    </div>
    <hr class="my-4">

    <div>

      <div class="p-2.5 mt-2 flex items-center rounded-md px-4 duration-300 cursor-pointer  hover:bg-grey-50">
        <i class="bi bi-house-door-fill"></i>
        <span class="text-[15px] ml-4 dark:text-t-dark">Home</span>
      </div>
      <div class="p-2.5 mt-2 flex items-center rounded-md px-4 duration-300 cursor-pointer  hover:bg-grey-50">
        <i class="bi bi-bookmark-fill"></i>
        <span class="text-[15px] ml-4  dark:text-t-dark">History</span>
      </div>
      <hr class="my-4">
      <div class="p-2.5 mt-2 flex justify-between items-center rounded-md px-4 duration-300">
        <i class="bi bi-envelope-fill"></i>
        <span class="text-[15px] ml-4 mr-auto  dark:text-t-dark">Dark Mode </span>
        <label class="relative inline-flex cursor-pointer items-center">
          <input [(ngModel)]="isDark" (change)="onClick()" id="switch" type="checkbox" class="peer sr-only" />
          <label for="switch" class="hidden">Dark Mode</label>
          <div class="peer h-6 w-11 rounded-full
          after:absolute
          after:left-[2px]
          after:top-0.5
          after:h-5
          after:w-5
          after:rounded-full
          after:content-['']
          peer-checked:after:translate-x-full
          after:transition-all
          bg-a-light
          peer-checked:after:bg-s-dark
          after:bg-white
          peer-checked:bg-grey-50"
          ></div>
        </label>
      </div>
    </div>
  </div>
</div>
