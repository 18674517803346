import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { webSocket } from 'rxjs/webSocket';
import { ClientDetailsResponse, MessageResponse } from '../interfaces/response';
import { enviroment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ChatbotService {
  clientName = new Subject<number>();

  subject = webSocket(enviroment.endpointWs + 'ws');
  //subject = webSocket(enviroment.endpointWs + 'ws-mock');

  message = new Subject<string>();

  inputDisable = new Subject<boolean>();

  stopMessage = new Subject<boolean>();

  constructor(private http: HttpClient) {}

  getWebSocket() {
    return this.subject;
  }

  sendMessage(message: string) {
    return this.http.get<MessageResponse>(
      enviroment.endpointHttp + 'chatbot/' + message
    );
  }

  getClientDetails(id: number) {
    return this.http.get<ClientDetailsResponse>(
      enviroment.endpointHttp + 'client_detail/' + id
    );
  }

  postDownload(path: string) {
    return this.http.post(
      enviroment.endpointHttp + 'download',
      { path: path },
      { responseType: 'blob' }
    );
  }

  getClientName() {
    return this.clientName;
  }

  getClientName$() {
    return this.clientName.asObservable();
  }

  getMessage() {
    return this.message;
  }

  getMessage$() {
    return this.message.asObservable();
  }

  getInputDisable() {
    return this.inputDisable;
  }

  getInputDisable$() {
    return this.inputDisable.asObservable();
  }

  getStopMessage() {
    return this.stopMessage;
  }

  getStopMessage$() {
    return this.stopMessage.asObservable();
  }

  scrollToBottom(): void {
    window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
  }
}
