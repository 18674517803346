<div
    class="max-lg:px-[15px] h-[100px] fixed top-0 w-full backdrop-blur-md z-[9] flex items-center justify-center border-b-[1px] border-solid border-grey-200 dark:border-dk-blue">
    <div class="max-w-[960px] w-[1280px] flex justify-between">
        <div class="flex justify-items-center justify-center gap-6">
            <ng-container *ngIf="isDarkMode; else isNotDarkMode">
                <img (click)="goHome()" src="../assets/ai_ada_light.svg" alt="logo"
                    class="w-[100px] cursor-pointer z-10">
            </ng-container>
            <ng-template #isNotDarkMode>
                <img (click)="goHome()" src="../assets/ai_ada.svg" alt="logo" class="w-[100px] cursor-pointer z-10">
            </ng-template>
        </div>
        <div class="flex justify-items-center justify-center gap-2">
            <button (click)="setTheme(isDarkMode)"
                class="dark:border rounded-lg dark:border-dk-blue-500 border border-grey-200 p-2 w-10 h-10 cursor-pointer text-gray-900 dark:text-grey-200">
                <ng-container *ngIf="isDarkMode">
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                            stroke="currentColor" class="w-6 h-6 hover:scale-125 dark:stroke-white-200">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                d="M12 3v2.25m6.364.386-1.591 1.591M21 12h-2.25m-.386 6.364-1.591-1.591M12 18.75V21m-4.773-4.227-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0Z" />
                        </svg>
                    </span>
                </ng-container>
                <ng-container *ngIf="!isDarkMode">
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                            stroke="currentColor" class="w-6 h-6 hover:scale-125 dark:stroke-white-200">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                d="M21.752 15.002A9.72 9.72 0 0 1 18 15.75c-5.385 0-9.75-4.365-9.75-9.75 0-1.33.266-2.597.748-3.752A9.753 9.753 0 0 0 3 11.25C3 16.635 7.365 21 12.75 21a9.753 9.753 0 0 0 9.002-5.998Z" />
                        </svg>
                    </span>
                </ng-container>
            </button>
            <ng-container *ngIf="isAudioVisible()">
                <button
                    class="dark:border rounded-lg dark:border-dk-blue-500 border border-grey-200 w-10 h-10 p-2 cursor-pointer text-gray-900 dark:text-grey-200"
                    (click)="toggleMute()">
                    <ng-container *ngIf="!isMuted">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                            stroke="currentColor" class="hover:scale-125 w-6 h-6 dark:stroke-white-200">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                d="M19.114 5.636a9 9 0 0 1 0 12.728M16.463 8.288a5.25 5.25 0 0 1 0 7.424M6.75 8.25l4.72-4.72a.75.75 0 0 1 1.28.53v15.88a.75.75 0 0 1-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.009 9.009 0 0 1 2.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75Z" />
                        </svg>
                    </ng-container>
                    <ng-container *ngIf="isMuted">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                            stroke="currentColor" class="w-6 h-6 hover:scale-125 dark:stroke-white-200">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                d="M17.25 9.75 19.5 12m0 0 2.25 2.25M19.5 12l2.25-2.25M19.5 12l-2.25 2.25m-10.5-6 4.72-4.72a.75.75 0 0 1 1.28.53v15.88a.75.75 0 0 1-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.009 9.009 0 0 1 2.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75Z" />
                        </svg>
                    </ng-container>
                </button>
            </ng-container>
        </div>
    </div>
</div>